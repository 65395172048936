/* eslint-disable max-len */
import React from 'react';
import Image from '../Image';

const BigScreen = () => (
  <div>
    <section className="bigscreen-section hidden-xs">
      <div className="container">
        <div className="bigscreen-faces clearfix">
          {/* <Image src="olya_in_circle.png" className="bigscreen-olya" /> */}
          {/* <Image src="skype.png" className="bigscreen-skype" /> */}
        </div>

        <Image
          src="kespa_text_only.png"
          alt="Kespa"
          className="bigscreen-image"
        />

        <div className="bigscreen-comment">
          Учебная платформа «Классрум», раздел с мини-текстами для отработки
          навыка говорения.
        </div>
      </div>
    </section>

    <div className="visible-xs">
      <div className="bigscreen-faces clearfix">
        {/* <Image src="olya_in_circle.png" className="bigscreen-olya" /> */}
        {/* <Image src="skype.png" className="bigscreen-skype" /> */}
      </div>
      <Image
        src="kespa_text_no_menu.png"
        alt="Kespa"
        className="bigscreen-image"
      />

      <div className="bigscreen-comment">
        Учебная платформа «Классрум», раздел с мини-текстами для отработки
        навыка говорения.
      </div>
    </div>
  </div>
);

export default BigScreen;
