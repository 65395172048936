/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../Image';

const blocks = [
  {
    title: 'Кому подходит методика КЭСПА?',
    text: (
      <div>
        <p>
          Прежде всего мы ориентируемся на взрослых мотивированных людей (20 лет
          и старше), которые понимают, зачем им нужен английский язык и готовы
          прикладывать усилия для его освоения. Для дошкольников и учеников
          младших классов разработана адаптированная версия методики КЭСПА,
          которая называется{' '}
          <a href="http://volshebnoe-pianino.ru/">«Волшебное пианино»</a>.
        </p>
        <p>
          Мы любим подчеркивать, что у нас отношение «ученик-учитель» скорее
          выглядит как «спортсмен-тренер». Мы не вкладываем в голову уже готовые
          знания и умения, но, опираясь на многолетний опыт преподавания,
          помогаем приобрести их наиболее эффективным образом.
        </p>
        <p>
          Уровень владения языком, с которым имеет смысл идти к нам – от
          нулевого до Upper-intermediate.
        </p>
        <p>
          Охотнее всего мы работаем с «подзабывшими» учениками – с теми, кто уже
          изучал английский в прошлом и хочет навести порядок в своей голове (их
          любимая фраза: <i>«Всё понимаю, но ничего сказать не могу!»</i>).
          Такие ученики чаще достигают «вау-эффекта» от занятий в КЭСПА – это
          когда уже после двух-трех недель занятий ученики восклицают:{' '}
          <i>«Вау! Я заговорил по-английски»</i>.
        </p>
      </div>
    ),
  },
  {
    title:
      'Как быстро можно заговорить с нуля, если заниматься по часу два раза в неделю?',
    text: (
      <div>
        <p>
          Это сильно зависит от ваших личных особенностей и того, что вы
          понимаете под словом «заговорить». Как происходит чаще всего:
        </p>
        <p>
          <ul className="colored">
            <li>
              На первом занятии ученик понимает суть методики и чувствует её
              эффективность на себе.
            </li>
            <li>
              Через 2-3 месяца ученик применяет простые конструкции языка для
              описания своей повседневной жизни.
            </li>
            <li>
              Через 8-10 месяцев ученик запоминает речевые формулы базового
              уровня (~110 штук, хватает для описания 95% процентов жизненных
              ситуаций) и употребляет их правильно и не задумываясь.
            </li>
          </ul>
        </p>
        <p>
          Мы не обещаем научить вас говорить по-английски за 2 месяца, но
          гарантируем значимый результат за адекватное время. Какого результата
          и за какое время достигнете лично вы, мы спрогнозируем по результатам
          бесплатного пробного занятия.
        </p>
      </div>
    ),
  },
  {
    title:
      'Если у меня уже неплохой уровень (Intermediate или выше), имеет ли смысл заниматься по вашей методике?',
    text: (
      <div>
        <p>
          Всё зависит от ваших целей – насколько уверенно и красиво вы хотите
          говорить по-английски, и от вашего реального уровня владения языком.
        </p>
        <p>
          Частая ситуация: человек с «опытом» изучения английского допускает
          грубые грамматические ошибки в каждом втором предложении, просто
          потому, что так заучил ещё в школе.
        </p>
        <p>
          <i>
            Если вы сомневаетесь в целесообразности уроков по нашей методике,
            запишитесь на пробное занятие. Это бесплатно, и за 25 минут вы
            оцените эффективность занятий лично для вас.
          </i>
        </p>
      </div>
    ),
  },
  {
    title:
      'Вижу, что ваша методика отлично подходит для освоения грамматики. А как набирать новую лексику?',
    text: (
      <div>
        <p>
          Общеупотребительная лексика набирается естественным образом во время
          выполнения наших упражнений. Новая грамматика осваивается на известной
          лексике, а новая лексика на известной грамматике – этот принцип
          реализован в двух типах наших упражнений:
        </p>
        <p>
          <ul className="colored">
            <li>техники речи – для освоения новых речевых формул</li>
            <li>мини-тексты – для расширения словарного запаса</li>
          </ul>
        </p>
        <p>
          Кроме этого, у каждого ученика в учебной системе есть персональный
          словарик, в который автоматически попадают его любимые слова.
        </p>
      </div>
    ),
  },
  {
    title: 'Преподают ли у вас носители?',
    text: (
      <div>
        <p>
          Нет, наши преподаватели русскоязычные, хотя многие проживают за
          рубежом. Обучение с носителем имеет смысл для учеников с продвинутым
          уровнем, которые уже знают в основном грамматику и способны понять
          объяснение на английском.
        </p>
        <p>
          Для начинающих и «подзабывших» более эффективны занятия с
          русскоязычными преподавателями. В&nbsp;отличие от носителей они сами
          когда-то прошли путь изучения английского языка, поэтому лучше
          понимают сложности, с которыми сталкиваются ученики.
        </p>
      </div>
    ),
  },
  {
    title: 'Готовите ли вы к экзаменам ЕГЭ, TOEFL, IELTS?',
    text: (
      <div>
        <p>
          Специально не готовим, но практика показывает, что люди, учившиеся у
          нас, потом быстро готовятся к экзаменам. Если есть прочная база в виде
          разговорного английского, дополнительно запомнить особенности и нюансы
          экзамена не составит труда.
        </p>
      </div>
    ),
  },
  {
    title: 'Я хочу преподавать по вашей методике! Как начать?',
    text: (
      <div>
        <p>
          Просто <Link to="/contacts">напишите нам</Link>!
        </p>
        <p>
          Педагогическое образование необязательно, но будет большим плюсом. В
          любом случае будьте готовы к тому, что придётся потратить некоторое
          время на стажировку, чтобы научиться преподавать по методике КЭСПА.
        </p>
        <Image src="may3.png" className="faq-teachers-welcome" />
      </div>
    ),
  },
];

class Block extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: props.id === 0,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      opened: !this.state.opened,
    });
  }

  render() {
    const { title, text } = this.props;
    const { opened } = this.state;
    return (
      <li className="faq-list-item">
        <button className="faq-list-item__title" onClick={this.toggle}>
          {title}
        </button>
        {opened && <div className="faq-list-item__description">{text}</div>}
      </li>
    );
  }
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
};

const FAQ = () => (
  <section id="faq-section" className="faq-section">
    <div className="container faq-container">
      <h2 className="faq__title">Частые вопросы</h2>
      <ul className="faq-list">
        {blocks.map((block, i) => (
          <Block {...block} key={i} id={i} />
        ))}
      </ul>
    </div>
  </section>
);

export default FAQ;
