/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const blocks = [
  {
    title: 'Знание ≠ Умение',
    text: (
      <div>
        <p>
          Прочитав и поняв правило по грамматике, ученик приобретает{' '}
          <i>знание</i>. Обычно преподаватели в языковых школах на этом
          останавливаются, считая свою миссию выполненной. В нашей школе на этом
          этапе основная работа только начинается :)
        </p>
        <p>
          Наша задача – не пройти учебник как можно быстрее, а приобрести{' '}
          <i>навык беглого говорения</i>. Мы не идем по программе дальше, если
          не добились достаточного автоматизма на текущем этапе.
        </p>
        <p>
          <span className="emphasized">Мы отвечаем за приобретение навыка</span>{' '}
          – таким образом, мы отвечаем за ваш результат в изучении английского
          языка.
        </p>
      </div>
    ),
  },
  {
    title: 'Только полезные задания',
    text: (
      <div>
        <p>
          Очевидный факт – чтобы научиться говорить, нужно говорить. Однако во
          многих онлайн и оффлайн школах до сих пор популярны упражнения вида
          «обведите правильный ответ в кружочек» или «впишите недостающее
          окончание» :(
        </p>
        <p>
          У нас таких упражнений вы не найдёте, поскольку они не связаны
          напрямую с процессом говорения и не развивают соответствующего навыка.
        </p>
        <p>
          В КЭСПА вы будете <i>говорить, говорить и ещё раз говорить</i> – с
          разной скоростью и интонацией, подключая разную лексику, а наш
          преподаватель проконтролирует, что вы всё делаете правильно.
        </p>
      </div>
    ),
  },
  {
    title: 'Собственные учебные материалы',
    text: (
      <div>
        <p>
          В отличие от большинства языковых школ, где учат по традиционным
          отечественным или зарубежным учебникам, мы используем собственные
          учебники и методику, сформированную за годы интенсивной практики. На
          их основе мы создали онлайн-уроки, которые соответствуют <i>нашему</i>{' '}
          представлению о правильном процессе обучения английскому языку.
        </p>
        <p>
          Методика – это наше всё, поэтому её описанию мы посвятили специальный
          раздел ниже.
        </p>
      </div>
    ),
  },
  {
    title: 'Персонализация обучения',
    text: (
      <div>
        <p>
          Чтобы учитывать речевые особенности и цели каждого конкретного
          ученика, мы придумали и активно применяем на практике такие понятия,
          как{' '}
          <Link to="/linguotype">
            «лингвопортрет» и «индивидуальный коммуникативный набор»
          </Link>
          .
        </p>
        <p>
          У нас не бывает занятий в группах, потому что только индивидуальные
          занятия позволяют добиться максимальной эффективности обучения.
        </p>
        <p>
          <span className="emphasized">
            Английский в КЭСПА – это штучный товар.
          </span>
        </p>
      </div>
    ),
  },
];

class Block extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: props.id === 0,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      opened: !this.state.opened,
    });
  }

  render() {
    const { title, text } = this.props;
    const { opened } = this.state;
    return (
      <li className="difference-list-item">
        <button className="difference-list-item__title" onClick={this.toggle}>
          {title}
        </button>
        {opened && (
          <div className="difference-list-item__description">{text}</div>
        )}
      </li>
    );
  }
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
};

const Difference = () => (
  <section className="difference-section">
    <div className="container difference-container">
      <h2 className="difference__title">
        Чем КЭСПА отличается от других онлайн-школ
        <br /> английского языка?
      </h2>

      <ol className="difference-list">
        {blocks.map((block, i) => (
          <Block {...block} key={i} id={i} />
        ))}
      </ol>
    </div>
  </section>
);

export default Difference;
