import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../Image';

const partners = [
  {
    url: 'http://aclass.ru/english',
    image: 'partners/aclass.png',
    name: 'English A-Class',
  },
  {
    url: 'http://www.traektoriafdn.ru/',
    image: 'partners/traektoria.png',
    name: 'Фонд Траектория',
  },
  {
    url: 'http://gravityagency.com/',
    image: 'partners/gravity.png',
    name: 'Gravity, social media agency',
  },
  {
    url: 'http://toastmasters.ru',
    image: 'partners/tm_moscow.png',
    name: 'Moscow Toastmasters',
  },
];

const PartnerCard = ({ url, image, name }) => (
  <a href={url} className="partner-card">
    <div className="partner-card-image">
      <Image src={image} alt={name} className="partner-card-image__img" />
    </div>
  </a>
);

PartnerCard.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Partners = () => (
  <section className="partners-section">
    <div className="container partners-block">
      <h2 className="partners-block__title">Наши партнеры</h2>
      <div className="partners-list">
        {partners.map((partner, i) => (
          <PartnerCard {...partner} key={i} />
        ))}
      </div>

      <p className="partners-message">
        Интересно сотрудничество? <Link to="/contacts">Напишите нам!</Link>
      </p>
    </div>
  </section>
);

export default Partners;
