import React from 'react';
import PropTypes from 'prop-types';

const StartStudyingButton = ({
  url = '/#signup-kespa',
  title = 'Записаться на вводный урок',
}) => (
  <a href={url} className="btn" title={title} style={{ whiteSpace: 'normal' }}>
    {title}
  </a>
);

StartStudyingButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

export default StartStudyingButton;
