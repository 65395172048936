/* eslint-disable max-len */
import React from 'react';
import Image from '../Image';
import StartStudyingButton from '../StartStudyingButton';

// import welcomeWithHats from "../../images/kespa_welcome_with_hats.png"

const IntroText = () => (
  <section className="intro-section">
    <div className="container">
      <div className="intro-words row">
        <div className="col-md-5 intro-text-block">
          <h1 className="intro-title">
            Методика
            <br />
            КЭСПА
          </h1>
          <h2 className="intro-subtitle">
            Как говорить по-английски
            <br />
            не задумываясь
          </h2>
          <StartStudyingButton />
          <p className="intro-button-comment">бесплатно, 30 минут онлайн</p>
        </div>

        <div className="col-md-7 intro-picture-block">
          <Image
            src="kespa_welcome_with_hats.png"
            alt="Kespa"
            className="intro-picture"
          />
        </div>
      </div>
    </div>
  </section>
);

export default IntroText;
