import React from 'react';
import { Link } from 'gatsby';
import Image from '../Image';

const GiventalCard = () => (
  <section className="givental-section">
    <div className="container givental-block">
      <h2 className="givental-block__title">Слово автору методики</h2>

      <div className="givental-card">
        <div className="givental-card-text">
          <p>
            КЭСПА-метод появился четверть века назад как своеобразная прививка
            против «немого» знания английского языка, чтобы помочь людям,
            которые много лет зубрили грамматику, преодолеть психологический
            языковой барьер и заговорить, наконец, по-английски.
          </p>
          <p>
            Научить говорить по-английски можно всех – даже тех, кто считает
            себя с рождения неспособным к языкам. Надо лишь учитывать личностные
            особенности каждого конкретного ученика.
          </p>
          <i
            className="givental-card-text__quote fa fa-quote-right"
            aria-hidden="true"
          />
        </div>

        <div className="givental-card-person">
          <div className="givental-card-circle-image">
            <Image
              src="givental_card.jpg"
              alt="Инна Гивенталь"
              className="givental-card-circle-image__img"
            />
          </div>
          <h3 className="givental-card-person__name">Инна Гивенталь</h3>
          <div className="givental-card-person__about">
            Филолог, создатель оригинальной методики обучения английскому языку
            КЭСПА, автор пяти учебников
          </div>
          <Link to="givental">
            <p className="givental-card-person__about_more">Подробнее...</p>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default GiventalCard;
