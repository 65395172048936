/* eslint-disable max-len */
import React from 'react';
import SignupMini from '../SignupMini';

const Intro = () => (
  <section id="signup-kespa" className="registration-section">
    <div className="container">
      <h2 className="registration-title">Записаться на вводный урок</h2>
      <div className="registration-subtitle">Бесплатно, 30 минут онлайн</div>

      <div className="row">
        <div className="col-sm-5">
          <div className="registration-row">
            <div className="registration-sigup-mini">
              <SignupMini />
            </div>
          </div>
        </div>

        <div className="col-sm-7">
          <ol className="registration-comments-list">
            <li className="registration-comments-list__item">
              Объясним, почему методика КЭСПА помогает заговорить по-английски
              быстрее
            </li>
            <li className="registration-comments-list__item">
              Покажем нашу учебную платформу
            </li>
            <li className="registration-comments-list__item">
              Определим ваш уровень и языковые особенности
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

export default Intro;
