/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../Image';

const blocks = [
  {
    title: 'Речевые формулы',
    text: (
      <div>
        <p>
          Традиционный подход к изучению английского языка – отталкиваться от
          грамматических времен:{' '}
          <i>Present Simple, Present Continuous, Past Simple</i> и т.д.
          Но&nbsp;скажите, учили ли вы что-то подобное, когда в один прекрасный
          день заговорили по-русски? Наверное, нет :)
        </p>
        <p>
          В методике КЭСПА преобладает естественный подход к изучению языка –
          использование устойчивых речевых конструкций, которые наполняются
          нужным вам смыслом:
        </p>

        <ul className="method-examples">
          <li>
            <span className="russian">Я умею делать это.</span>{' '}
            <span className="comment">
              (читать, писать, говорить по-английски…)
            </span>
          </li>
          <li>
            <span className="russian">Ты всегда делаешь это!</span>{' '}
            <span className="comment">(опаздываешь, забываешь ключи…)</span>
          </li>
          <li>
            <span className="russian">Он уже сделал это.</span>{' '}
            <span className="comment">(пришел, пообедал, позвонил маме…)</span>
          </li>
        </ul>

        <p>
          Такие устойчивые конструкции в методике КЭСПА называются{' '}
          <i>речевыми формулами</i>. В примерах выше в роли сменной смысловой
          составляющей выступает глагол, то есть часть речи, обозначающая{' '}
          <i>действие</i>. А вот примеры речевых формул, выражающие{' '}
          <i>отношение</i> говорящего человека к действию:
        </p>

        <ul className="method-examples">
          <li>
            <span className="russian">Ты должен сделать это.</span>{' '}
            <span className="comment">
              (написать, приготовить, понять…) – эта речевая формула выражает
              необходимость определенного действия.
            </span>
          </li>
          <li>
            <span className="russian">Тебе нельзя делать это.</span>{' '}
            <span className="comment">
              (курить, подслушивать, опаздывать…) – эта речевая формула
              запрещает какое-то конкретное действие.
            </span>
          </li>
          <li>
            <span className="russian">Неужели ты уже сделал это?!</span>{' '}
            <span className="comment">
              (написал, приготовил, понял…) – эта речевая формула выражает
              удивление перед фактом, что действие уже произошло.
            </span>
          </li>
        </ul>

        <p>
          Оказывается, таких устойчивых конструкций в английском языке не так уж
          много: 110 речевых формул хватит, чтобы общаться на бытовые темы в 95%
          ситуаций, а для уверенного владения языком на уровне носителя таких
          формул нужно около 300.
        </p>
      </div>
    ),
  },
  {
    title: 'Артикуляционная память',
    text: (
      <div>
        <p>
          Как же выучить эти заветные 110 (или даже 300) речевых формул?
          <br />
          Всё просто: чтобы научиться говорить, нужно{' '}
          <i>говорить, говорить и ещё раз говорить</i>! И тогда{' '}
          <i>артикуляционная память</i> сделает своё дело.
        </p>
        <p>
          Артикуляционная память – это моторно-двигательная память на
          произношение речевых конструкций, которая развивается в процессе
          говорения.
        </p>
        <p>
          Вспомните, как вы учились кататься на велосипеде, играть на
          музыкальном инструменте или водить машину: никогда не получалось
          сделать всё правильно с первого раза, однако после нескольких
          повторений мозг и мышцы привыкали к новым движениям, и вы уже
          выполняли довольно сложные действия не задумываясь!
        </p>
        <p>
          То же самое происходит и с английским языком: чтобы речевые формулы
          надолго остались в голове и на языке, их нужно достаточное число раз
          проговорить.
        </p>

        <div className="row">
          <div className="col-md-8">
            <p>
              А чтобы мозг не уставал из-за однотипности фраз, нужно при каждом
              повторе что-то немножко менять. Например:
            </p>
            <ul className="colored">
              <li>высоту и громкость голоса</li>
              <li>скорость речи и ритмический рисунок</li>
              <li>сопутствующие движения руками</li>
              <li>настроение :)</li>
            </ul>
          </div>
          <div className="col-md-4">
            <Image src="articulation_small.png" className="" />
          </div>
        </div>
        <p>
          Этот прием называется{' '}
          <a href="http://www.postpsychology.org/literature/autodidactics-1/">
            «творческий повтор Куринского»
          </a>{' '}
          и его эффективность проверена на практике.
        </p>
        <p>
          Кроме того, сами речевые формулы включают в себя сменную смысловую
          составляющую: при отработке новой речевой формулы можно использовать
          уже изученную лексику – например, подставлять разные глаголы, как в
          примерах выше. И наоборот – на уже изученных речевых формулах
          осваивать новую лексику.
        </p>
      </div>
    ),
  },
  {
    title: 'Персональная программа',
    text: (
      <div>
        <p>
          В зависимости от начального уровня и целей ученика из речевых формул
          составляется персональная программа обучения. Для удобства мы
          объединили речевые формулы в тематические блоки (например,{' '}
          <i>
            «Человек описывает себя», «Человек действует», «Человек рассказывает
            и объясняет»
          </i>
          ).
        </p>
        <p>
          Для каждой речевой формулы на нашей учебной платформе отображается
          статус её освоения:
        </p>
        <div className="method-formula-statuses-description">
          <div className="row">
            <div className="col-xs-3">
              <div className="method-formula__unknown">неизвестная</div>
            </div>
            <div className="col-xs-9 comment">
              {' '}
              – данную формулу ещё не отрабатывали
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3">
              <div className="method-formula__head">в голове</div>
            </div>
            <div className="col-xs-9 comment">
              {' '}
              – теория понята и, если подумать, можно правильно составить
              предложение с этой речевой формулой
            </div>
          </div>

          <div className="row">
            <div className="col-xs-3">
              <div className="method-formula__tongue">на языке</div>
            </div>
            <div className="col-xs-9 comment">
              {' '}
              – речевая формула отработана до такой степени, что на её основе
              человек не задумываясь быстро и правильно строит предложения
            </div>
          </div>
        </div>

        <p>
          Статусы меняет преподаватель и таким образом в КЭСПА отслеживается
          прогресс обучения. Цель будет достигнута, когда все формулы из
          персональной программы со статусами <i>«неизвестная»</i> и{' '}
          <i>«в голове»</i> перейдут в статус <i>«на языке»</i>.
        </p>

        <p>
          Персональная программа – это своеобразная дорожная карта. Помимо
          отображения прогресса она помогает ученику и преподавателю планировать
          дальнейшие шаги.
        </p>

        <p>Преимущества персональной программы:</p>

        <ul className="colored big-items">
          <li>
            <span className="highlight">Понятность учебного процесса</span>
            <br />
            Cтатусы речевых формул показывают, над чем ученику нужно работать в
            данный момент.
          </li>

          <li>
            <span className="highlight">Предсказуемость результата</span>
            <br />
            Ученик всегда знает, сколько речевых формул он прошёл к настоящему
            моменту и сколько ещё осталось для полного освоения персональной
            программы. Поэтому спрогнозировать суммарное время обучения не
            составляет большого труда.
          </li>

          <li>
            <span className="highlight">
              Конкретность цели и её достижимость
            </span>
            <br />
            Освоить 110 речевых формул из персональной программы «Для
            начинающих» - это очень конкретная и достижимая цель. Психологически
            с такой целью работать намного проще, чем с абстрактной задачей
            «выучить английский».
          </li>

          <li>
            <span className="highlight">Гибкость обучения</span>
            <br />
            Речевые формулы можно осваивать в произвольной последовательности.
            Например, если вы начальник в большой компании и очень любите фразу{' '}
            <i>«Ты должен сделать это к утру!»</i>, - замечательно! -
            соответствующую речевую формулу преподаватель даст на освоение в
            первую очередь, чтобы как можно быстрее вывести ваши частотные
            формулы в речь.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Лингвопортрет',
    text: (
      <div>
        <p>
          Мы хорошо понимаем – чтобы добиться наибольшей эффективности в
          обучении английскому языку, разных людей нужно учить по-разному.
          Поэтому ещё до начала обучения в КЭСПА мы определяем речевые
          особенности каждого ученика и составляем его <i>«лингвопортрет»</i>.
        </p>

        <p>Лингвопортрет описывает ученика по следующим параметрам:</p>
        <ul className="colored">
          <li>скорость и внятность речи</li>
          <li>
            инициативность в разговоре (охотный или вынужденный собеседник)
          </li>
          <li>
            склонность использовать сложную или простую лексику (речевой
            максималист или минималист)
          </li>
          <li>
            ритмическая отзывчивость, т.е. насколько хорошо человек слышит и
            имитирует чужую речь
          </li>
        </ul>

        <p>
          В зависимости от результатов мы подбираем подходящего преподавателя и
          дальше преподаватель дает ученику только те упражнения, которые
          соответствуют его лингвопортрету.
        </p>
        <p>
          Например, если человек – <i>речевой минималист</i>, т.е. говорит
          только то, что нужно и ничего лишнего, преподаватель будет давать ему
          упражнения с минимумом лексики и основной грамматикой. А для{' '}
          <i>речевых максималистов</i> упражнения будет совсем другими – с
          идиомами, сложносочиненными и сложноподчиненными предложениями.
        </p>
        <p>
          И такая персонализация относится ко всем пунктам{' '}
          <Link to="/linguotype">лингвопортрета</Link>!
        </p>
      </div>
    ),
  },
];

class Block extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: props.id === 0,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      opened: !this.state.opened,
    });
  }

  render() {
    const { title, text } = this.props;
    const { opened } = this.state;
    return (
      <li className="method-list-item">
        <button className="method-list-item__title" onClick={this.toggle}>
          {title}
        </button>
        {opened && <div className="method-list-item__description">{text}</div>}
      </li>
    );
  }
}

Block.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
};

const MethodHighlights = () => (
  <section id="method-section" className="method-section">
    <div className="container method-container">
      <h2 className="method__title">Методика</h2>

      <p className="method-comment">
        КЭСПА – это аббревиатура вопроса «Как Это Сказать По-Английски?».
        Так&nbsp;называется наша школа и методика. Суть методики в двух словах:
      </p>

      <div className="row">
        <div className="col-md-8">
          <p className="method-essence">
            Чтобы научиться говорить, нужно&nbsp;говорить! А&nbsp;эффективность
            этого процесса зависит от&nbsp;того, ЧТО&nbsp;говорить и&nbsp;КАК
          </p>
          <i
            className="method-essence__quote fa fa-quote-right"
            aria-hidden="true"
          />
        </div>
      </div>

      <p className="method-comment">
        Кто-то уверен, что нужно сразу идти общаться с&nbsp;носителями языка.
        Кто-то выбирает занятия в&nbsp;группах по&nbsp;коммуникативным
        методикам. Однако мы считаем, что говорить по-английски
        «как&nbsp;получится» и заучивать таким образом собственные ошибки – не
        самый лучший способ заговорить.
      </p>

      <p className="method-comment">
        Намного эффективнее довериться преподавателю, который знает, какие
        упражнения и в какой последовательности нужно выполнять, чтобы как можно
        быстрее заговорить по-английски, правильно и не задумываясь.
      </p>

      <p className="method-comment">
        Чуда в нашей методике нет, но есть проверенные временем законы, по
        которым работает человеческая память. Чтобы стало понятнее, мы расписали
        основные пункты методики ниже.
      </p>

      <ol className="method-list">
        {blocks.map((block, i) => (
          <Block {...block} key={i} id={i} />
        ))}
      </ol>
    </div>
  </section>
);

export default MethodHighlights;
