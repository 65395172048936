import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Image from '../Image';

const teachers = [
  // {
  //   image: 'teachers/givental.jpg',
  //   name: 'Инна Гивенталь',
  //   title: 'главный методист'
  // },
  {
    image: 'teachers/strahov.jpg',
    name: 'Алексей Страхов',
    senior: true,
  },
  {
    image: 'teachers/jironkina.jpg',
    name: 'Ольга Жиронкина',
    senior: true,
  },
  {
    image: 'teachers/chekalyova.jpg',
    name: 'Надежда Чекалева',
    senior: true,
  },
  {
    image: 'teachers/zolotova.jpg',
    name: 'Екатерина Алтунина',
    senior: true,
  },
  {
    image: 'teachers/erpeleva.jpg',
    name: 'Вера Ерпелева',
    senior: true,
  },
  {
    image: 'teachers/skrinnikova.jpg',
    name: 'Елена Скрынникова',
    senior: true,
  },
  // {
  //   image: 'teachers/ovsyannikova.jpg',
  //   name: 'Анастасия Овсянникова',
  //   senior: true,
  // },
  {
    image: 'teachers/hohlova.jpg',
    name: 'Юлия Хохлова',
  },
  {
    image: 'teachers/churina.jpg',
    name: 'Анастасия Чурина',
  },
  {
    image: 'teachers/knyazeva.jpg',
    name: 'Алёна Гигель',
  },
  // {
  //   image: 'teachers/opaleva.jpg',
  //   name: 'Мария Опалева',
  // },
  // {
  //   image: 'teachers/lavlinskaya.jpg',
  //   name: 'Анна Лавлинская',
  // },
  // {
  //   image: 'teachers/ivleva.jpg',
  //   name: 'Диана Ивлева',
  // },
  {
    image: 'teachers/fokina.jpg',
    name: 'Таисия Фокина',
  },
  // {
  //   image: 'teachers/strateva.jpg',
  //   name: 'Наталья Стратева',
  // },
  // {
  //   image: 'teachers/victoria.jpg',
  //   name: 'Виктория Жильцова',
  // },
  // {
  //   image: 'teachers/velichko.jpg',
  //   name: 'Юлия Величко',
  // },
  {
    image: 'teachers/pavlov.jpg',
    name: 'Евгений Павлов',
  },
  {
    image: 'teachers/venkova.jpg',
    name: 'Ксения Венкова',
  },
  {
    image: 'teachers/misreli.jpg',
    name: 'Рина Мисрели',
  },
  {
    image: 'teachers/varlamov.jpg',
    name: 'Павел Варламов',
  },
  {
    image: 'teachers/kolomeychuk.jpg',
    name: 'Александра Коломейчук',
  },
  {
    image: 'teachers/mukabenova.jpg',
    name: 'Занда Мукабенова',
  },
  {
    image: 'teachers/pribytkova.jpg',
    name: 'Ольга Прибыткова',
  },
  {
    image: 'teachers/gurevich.png',
    name: 'Олеся Гуревич',
  },
];

const TeacherCard = ({ image, name, senior }) => (
  <div className="teacher-card">
    <div className="teacher-card-circle-image">
      <Image
        src={image}
        alt={name}
        className="teacher-card-circle-image__img"
      />
    </div>
    <div className="teacher-card-info">
      <h3 className="teacher-card-info__name">
        {name}
        {(() => {
          if (senior) {
            return (
              <sup>
                <i className="teacher-senior fa fa-asterisk" />
              </sup>
            );
          }
          return null;
        })()}
      </h3>
    </div>
  </div>
);

TeacherCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  senior: PropTypes.bool,
};

const Teachers = () => (
  <section className="teachers-section">
    <div className="teachers-block container">
      <h2 className="teachers-block__title">Наши преподаватели</h2>
      <div className="teachers-block__subtitle">
        и старшие преподаватели{' '}
        <sup>
          <i className="teacher-senior fa fa-asterisk" />
        </sup>
      </div>
      <div className="teachers-list">
        {teachers.map((teacher, i) => (
          <TeacherCard {...teacher} key={i} />
        ))}
      </div>

      <p className="teachers-hire">
        Тоже хотите преподавать по методике КЭСПА?
        <br />
        <Link to="/contacts">Напишите нам!</Link>
      </p>
    </div>
  </section>
);

export default Teachers;
