import React from 'react';
import Image from '../Image';

const Box = ({ image, title, comment, price }) => (
  <div className="col-md-6">
    <div className="price-box">
      <Image src={image} className="price-box__price-logo" />
      <h3>{title}</h3>
      <p className="comment">{comment}</p>
      <p className="price-line">{price}</p>
    </div>
  </div>
);

const Prices = () => (
  <section id="prices" className="prices-section">
    <div className="prices-block container">
      <h2 className="prices-block__title">Стоимость занятий</h2>
      <div className="row prices-block__row">

        <Box
          image="level_2.png"
          title={`50-минутные
уроки`}
          comment="Преподаватель будет выявлять ваши слабые места, подбирать упражнения и предоставлять учебные материалы. На каждом уроке проверяется не только домашнее задание, но и весь освоенный грамматический материал."
          price={
            <React.Fragment>
              <span className="price-tag">от 1375 руб.</span>
              <br />
              за занятие
            </React.Fragment>
          }
        />
        <Box
          image="level_1.png"
          title={`Интересны иные
форматы обучения?`}
          comment="Помимо классических уроков один на один с преподавателем, вы можете заниматься с нашими методистами или попробовать форматы гибридного образования, когда большая часть обучения проходит вне уроков. Напишите или позвоните нам, и мы расскажем об этом подробнее!"
          // price={
          //   <React.Fragment>
          //     <span className="price-tag">4900 руб.</span>
          //     <br />в месяц
          //   </React.Fragment>
          // }
        />
      </div>
    </div>
  </section>
);

export default Prices;
