import React from 'react';
import Helmet from 'react-helmet';

import IntroText from '../components/Home/IntroText';
import MethodHighlights from '../components/Home/MethodHighlights';
import Difference from '../components/Home/Difference';
import Registration from '../components/Home/Registration';
import Prices from '../components/Home/Prices';
import Teachers from '../components/Home/Teachers';
import Partners from '../components/Home/Partners';
import GiventalCard from '../components/Home/GiventalCard';
import BigScreen from '../components/Home/BigScreen';
import FAQ from '../components/Home/FAQ';

import BlueLine from '../components/BlueLine';
import Layout from '../components/Layout';

const Home = () => (
  <Layout>
    <div className="landing">
      <Helmet title="КЭСПА – онлайн-школа английского с правильной методикой" />
      <IntroText />
      <Difference />
      <MethodHighlights />
      <GiventalCard />
      <Prices />
      <Registration />
      <BigScreen />
      <FAQ />
      <Partners />
      <BlueLine />
      <Teachers />
    </div>
  </Layout>
);

export default Home;
